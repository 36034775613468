<template>
	<div class="card" v-if="nuevoProducto">
		<!-- <CrudProveedor></CrudProveedor> -->
	</div>
	<ProductoCreate
		:mostrar="mostrarProductoModal"
		:producto="producto"
		@closeModal="cerrarModal"
		@actualizarListadoProductos="obtenerListadoProductos"
	></ProductoCreate>
	<ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
		<li>
			<a class="text-500 no-underline line-height-3 cursor-pointer"
				>Par&aacute;metros</a
			>
		</li>
		<li class="px-2">
			<i class="pi pi-angle-right text-500 line-height-3"></i>
		</li>
		<li>
			<a class="text-500 no-underline line-height-3 cursor-pointer"
				>Productos</a
			>
		</li>
		<li class="px-2">
			<i class="pi pi-angle-right text-500 line-height-3"></i>
		</li>
		<li>
			<router-link to="/productos" class="text-900 line-height-3"
				>Listado de Productos</router-link
			>
		</li>
	</ul>
	<div class="surface-section px-4 py-5 md:px-6 lg:px-8">
		<div
			class="flex align-items-start flex-column lg:justify-content-between lg:flex-row"
		>
			<div>
				<div class="font-medium text-3xl text-900">Administrar Productos</div>
				<div class="flex align-items-center text-700 flex-wrap">
					<div class="mr-5 flex align-items-center mt-3">
						<i class="pi pi-box mr-2"></i>
						<span>{{ totalRegistros }} Productos</span>
					</div>
				</div>
			</div>
			<div class="flex mt-3 lg:mt-0">
				<Button
					label="Nuevo"
					v-if="'Producto Crear' in auth.user.permissions"
					class="p-button-outlined mr-2"
					icon="pi pi-plus"
					v-tooltip.top="'Registrar Nuevo Producto'"
					@click="openModal"
          :disabled="!cargando ? false : true"
          
				></Button>
			</div>
		</div>
		<div class="mt-4">
			<DataTable
				ref="dtlaboratorios"
				:value="productos"
				:loading="cargando"
				dataKey="id"
				:paginator="true"
				:filters="buscar"
				:rows="10"
				:rowHover="true"
				class="p-datatable-sm"
				paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
				:rowsPerPageOptions="[5, 10, 100, 1000]"
				currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} Productos"
				responsiveLayout="scroll"
			>
				<template #header>
					<div
						class="flex flex-column md:flex-row md:justify-content-end md:align-items-end"
					>
						<span class="block mt-2 md:mt-0 p-input-icon-left">
							<i class="pi pi-search" />
							<InputText
								v-model="buscar['global'].value"
								placeholder="Buscar Producto..."
							/>
						</span>
					</div>
				</template>
				<template #loading>
					<div class="flex align-items-center justify-content-center">
						<ProgressSpinner />
					</div>
				</template>
				<template #empty>
					<span class="flex align-items-center justify-content-center p-invalid"
						>No existen Registros!</span
					></template
				>
				<Column header="#">
					<template #body="slotProps">
						{{ 1 + slotProps.index }}
					</template>
				</Column>

				<Column field="codigo_barra" header="CÓDIGO INTERNO" :sortable="true">
					{{ data.codigo_barra }}
				</Column>
				<Column field="descripcion" header="DESCRIPCIÓN">
					{{ data.descripcion }}
				</Column>
				<!-- <Column field="fabrica_id" header="Codigo Laboratorio">
          {{ data.fabrica_id }}
        </Column> -->
				<Column field="fabrica_nombre" header="LABORATORIO" :sortable="true">
					{{ data.fabrica_nombre }}
				</Column>
				<!-- <Column field="unidadmedida_id" header="Codigo Unidad Medida">
          {{ data.unidadmedida_id }}
        </Column> -->
				<Column field="unidad_medida_nombre" header="UNIDAD MEDIDA">
					{{ data.unidad_medida_nombre }}
				</Column>
				<Column
					field="stock_minimo"
					header="STOCK MÍNIMO"
					class="text-right"
					:sortable="true"
				>
					{{ data.stock_minimo }}
				</Column>
				<Column
					field="stock_maximo"
					header="STOCK MÁXIMO"
					class="text-right"
					:sortable="true"
				>
					{{ data.stock_maximo }}
				</Column>
				<Column field="precio_sugerido" header="PRECIO SUGERIDO"></Column>
				<Column field="estado" header="ESTADO">
          <template #body="{ data }">
            <span :class="'product-badge ' + claseEstado[data.estado.value]">
              {{ data.estado.label }}
            </span>
          </template>
        </Column>
				<Column headerStyle="min-width:10rem;" header="ACCIONES">
					<template #body="slotProps">
						<Button
							class="p-button-icon-only p-button-raised"
							v-tooltip.top="'Ver Acciones'"
							icon="pi pi-ellipsis-v"
							@click="$refs['menu' + slotProps.data.id].toggle($event)"
						/>
						<Menu
							:ref="'menu' + slotProps.data.id"
							:popup="true"
							:model="acciones(slotProps.data)"
						>
						</Menu>
					</template>
				</Column>
			</DataTable>
		</div>
		<Dialog
			v-model:visible="deleteProdModal"
			:style="{ width: '450px' }"
			header="Confirmar"
			:modal="true"
		>
			<div class="flex align-items-center justify-content-center">
				<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
				<span v-if="producto"
					>Estas Seguro que quieres eliminar producto:
					<b>{{ producto.descripcion }}</b> ?</span
				>
			</div>
			<template #footer>
				<Button
					label="No"
					icon="pi pi-times"
					class="p-button-secondary"
					@click="deleteProdModal = false"
				/>
				<Button
					label="Si"
					icon="pi pi-check"
					class="p-button-primary"
					@click="deleteProducto"
				/>
			</template>
		</Dialog>
	</div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import ProductService from "@/service/ProductService";
import ProductoCreate from "@/module/productos/ProductoCreate.vue";
import { useAuth } from "@/stores";
//nuevo o editar
export default {
	components: { ProductoCreate },
	data() {
		return {
			nuevoProducto: false,
			totalRegistros: 0,
			productos: [],
			cargando: true,
			mostrarProductoModal: false,
			producto: {},
			deleteProdModal: false,
			buscar: {},
      claseEstado: ["status-outofstock", "status-instock"],
		};
	},
	productoService: null,
	auth: null,
	created() {
		this.auth = useAuth();
		this.productoService = new ProductService();
		this.initBuscar();
	},
	mounted() {
		this.obtenerListadoProductos();
	},
	methods: {
		acciones(datos) {
			let permisosUsuarioAuth = this.auth.user.permissions;
			return [
				{
					label: "Editar",
					disabled: "Producto Editar" in permisosUsuarioAuth ? false : true,
					icon: "pi pi-pencil",
					command: () => {
						this.editarProducto(datos);
					},
				},
				{
					label: "Ver Detalle",
					disabled:
						"Producto Ver Detalle" in permisosUsuarioAuth ? false : true,
					icon: "pi pi-eye",
					command: () => {
						this.verDetalle(datos);
					},
				},
				{
					label: "Eliminar",
					disabled: "Producto Eliminar" in permisosUsuarioAuth ? false : true,
					icon: "pi pi-trash",
					command: () => {
						this.confirmDeleteProducto(datos);
					},
				},
			];
		},
		openModal() {
			this.producto = {
				estado: { label: "Activo", value: 1 },
				stock_minimo: 0,
				stock_maximo: 0,
				precio_sugerido: 0,
			};
			this.mostrarProductoModal = true;
		},
		cerrarModal() {
			this.mostrarProductoModal = false;
			this.producto = {};
		},
		obtenerListadoProductos() {
			this.productoService
				.getAllProductos()
				.then((response) => {
					this.cargando = false;
					this.productos = response.productos;
					this.totalRegistros = this.productos.length;
					this.productos.forEach((producto) => {
						producto.estado = {
							label: this.textoEstado(producto.estado),
							value: producto.estado,
						};
					});
				})
				.catch((errors) => {
					console.log(errors);
				});
		},
		textoEstado(estado) {
			return estado == 1 ? "Activo" : "Inactivo";
		},
		editarProducto(info_producto) {
			this.producto = { ...info_producto };
			this.producto.stock_minimo = parseInt(this.producto.stock_minimo);
			this.producto.stock_maximo = parseInt(this.producto.stock_maximo);
			this.producto.precio_sugerido = parseFloat(
				this.producto.precio_sugerido
			);

			this.mostrarProductoModal = true;
		},
		verDetalle(producto) {
			this.$router.push(`/producto/${producto.id}/detalle`);
		},
		confirmDeleteProducto(producto) {
			this.producto = { ...producto };
			this.deleteProdModal = true;
		},
		deleteProducto() {
			this.productoService.deleteProducto(this.producto).then((response) => {
				this.productos = this.productos.filter(
					(val) => val.id !== this.producto.id
				);
				this.deleteProdModal = false;
				this.producto = {};

				this.$toast.add({
					severity: "success",
					summary: "Exito",
					detail: response.mensaje,
					life: 3000,
				});
			});
		},
		initBuscar() {
			this.buscar = {
				global: { value: null, matchMode: FilterMatchMode.CONTAINS },
			};
		},
	},
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";

.p-invalid {
	color: red;
}
</style>
